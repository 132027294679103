var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VForm",
    {
      ref: "review-form",
      staticClass: "review-form",
      attrs: { "data-qa": "review_form" },
    },
    [
      _c("SelectBottomSheet", {
        ref: "review-select-patient",
        attrs: {
          "data-qa": "review_select_patient",
          "use-bottom-sheet": _vm.isMobile,
          "select-props": {
            ref: "review-field-patient",
            label: "Кто был пациентом",
            itemText: "title",
            itemTextSecondary: "subtitle",
            rules: _vm.rulesValidationSelectedPatient,
          },
          items: _vm.patientsFromAppointment,
          "selected-item": _vm.inputData.selectedPatient,
        },
        on: { "select:change": _vm.updateSelectedPatient },
      }),
      _vm._v(" "),
      _c("VExpandTransition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSelectedPatientAnotherMan,
                expression: "isSelectedPatientAnotherMan",
              },
            ],
          },
          [
            _c(
              "div",
              { staticClass: "mb-8" },
              [
                _c("VTextField", {
                  ref: "review-field-other-patient",
                  attrs: {
                    "data-qa": "review-field_other_patient",
                    outlined: "",
                    label: "Кем вам приходится человек из отзыва",
                    value: _vm.inputData.otherPatientFromReview,
                    rules: _vm.rulesValidationOtherPatientFromReview,
                    maxlength: _vm.MAX_FIELD_OTHER_PATIENT_LENGTH,
                  },
                  on: { input: _vm.updateOtherPatientFromReview },
                }),
                _vm._v(" "),
                _c(
                  "BaseAlert",
                  {
                    attrs: {
                      type: "yellow",
                      icon: "ui-icon-circle-warning",
                      "data-qa": "review-field_other_patient_alert",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "ui-text ui-text_subtitle-2 mb-2" },
                      [
                        _vm._v(
                          "\n            Отзыв должен оставлять сам пациент или его близкий родственник\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ui-text ui-text_body-2" }, [
                      _vm._v(
                        "\n            В другом случае отзыв будет отклонён во время проверки.\n          "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.itemsLpuAddresses.length
        ? _c("SelectBottomSheet", {
            ref: "review-select-address",
            attrs: {
              "data-qa": "review_select_address",
              disabled: _vm.commonData.isReviewSourcePrs,
              "use-bottom-sheet": _vm.isMobile,
              "select-props": {
                ref: "review-field-address",
                label: "Клиника",
                itemValue: "id",
                itemText: "lpuName",
                itemTextSecondary: "address",
                rules: _vm.rulesValidationSelectedLpuAddressId,
              },
              items: _vm.itemsLpuAddresses,
              "selected-item": _vm.inputData.selectedLpuAddressId,
            },
            on: { "select:change": _vm.updateSelectedLpuAddressId },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "VExpandTransition",
        [
          _c("VTextField", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSelectedLpuAddressOther,
                expression: "isSelectedLpuAddressOther",
              },
            ],
            ref: "review-field-other-address",
            attrs: {
              "data-qa": "review_field_other_address",
              outlined: "",
              label: "Название клиники и адрес",
              value: _vm.inputData.otherLpuNameAndAddress,
              rules: _vm.rulesValidationOtherLpuNameAndAddress,
              maxlength: _vm.MAX_FIELD_OTHER_LPU_NAME_LENGTH,
            },
            on: { input: _vm.updateOtherLpuNameAndAddress },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isPowerInfoPlateVisible
        ? _c(
            "VSheet",
            {
              staticClass: "d-flex align-center px-3 py-4 mb-7 cursor-pointer",
              attrs: { color: "uiKitBgWarning", rounded: "lg" },
              on: { click: _vm.handleClickReviewPowerInfo },
            },
            [
              _c(
                "VIcon",
                {
                  staticClass:
                    "ui-icon_type_40 ui-kit-bg-warning flex-shrink-0 mr-4",
                  attrs: { color: "uiKitBgGray0" },
                },
                [_vm._v("\n      ui-icon-piggy-bank-40\n    ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_subtitle-1 ui-kit-color-text-warning mr-4",
                },
                [_vm._v("\n      Дарим скидку 300 ₽ за честный отзыв\n    ")]
              ),
              _vm._v(" "),
              _c("VSpacer"),
              _vm._v(" "),
              _c("VIcon", { attrs: { color: "uiKitTextWarning" } }, [
                _vm._v("\n      ui-icon-arrow-right\n    "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("DatePickerMonth", {
        ref: "review-select-date",
        attrs: {
          "data-qa": "review_select_date",
          "use-dialog": _vm.isMobile,
          "text-field-props": {
            ref: "review-field-date",
            label: "Дата приёма",
            rules: _vm.rulesValidationSelectedDate,
          },
          "date-picker-props": {
            min: _vm.minSelectedDate,
            max: _vm.maxSelectedDate,
          },
          "selected-date": _vm.inputData.selectedDate,
        },
        on: { "date-picker:change": _vm.updateSelectedDate },
      }),
      _vm._v(" "),
      _c("ReviewFormTextareaAlerts"),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { "data-qa": "review_field_comment" } },
        [
          _c("VTextarea", {
            ref: "review-field-comment",
            staticClass: "review-form__textarea",
            attrs: {
              "data-qa": "review_field_comment_input",
              outlined: "",
              "auto-grow": "",
              name: _vm.TEXTAREA_NAMES.comment,
              error: _vm.inputData.comment.length > _vm.MAX_REVIEW_FIELD_LENGTH,
              counter:
                _vm.inputData.comment.length > _vm.MAX_REVIEW_FIELD_LENGTH,
              maxlength: _vm.OVER_MAX_REVIEW_FIELD_LENGTH,
              rows: "10",
              label: "Ваша история",
              placeholder: _vm.commentPlaceholder,
              rules: _vm.rulesValidationComments,
              value: _vm.inputData.comment,
            },
            on: {
              input: function ($event) {
                return _vm.handleInputTextarea(
                  _vm.TEXTAREA_NAMES.comment,
                  $event
                )
              },
              focus: function ($event) {
                return _vm.handleFocusTextarea(
                  _vm.TEXTAREA_NAMES.comment,
                  $event
                )
              },
              blur: function ($event) {
                return _vm.handleBlurTextarea(
                  _vm.TEXTAREA_NAMES.comment,
                  $event
                )
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { "data-qa": "review_field_comment_plus" } },
        [
          _c("VTextarea", {
            ref: "review-field-comment-plus",
            staticClass: "review-form__textarea",
            attrs: {
              "data-qa": "review_field_comment_plus_input",
              outlined: "",
              "auto-grow": "",
              name: _vm.TEXTAREA_NAMES.commentPlus,
              error:
                _vm.inputData.commentPlus.length > _vm.MAX_REVIEW_FIELD_LENGTH,
              counter:
                _vm.inputData.commentPlus.length > _vm.MAX_REVIEW_FIELD_LENGTH,
              maxlength: _vm.OVER_MAX_REVIEW_FIELD_LENGTH,
              rows: "4",
              label: "Понравилось",
              placeholder: "Здесь можно указать главные плюсы",
              rules: _vm.rulesValidationComments,
              value: _vm.inputData.commentPlus,
            },
            on: {
              input: function ($event) {
                return _vm.handleInputTextarea(
                  _vm.TEXTAREA_NAMES.commentPlus,
                  $event
                )
              },
              focus: function ($event) {
                return _vm.handleFocusTextarea(
                  _vm.TEXTAREA_NAMES.commentPlus,
                  $event
                )
              },
              blur: function ($event) {
                return _vm.handleBlurTextarea(
                  _vm.TEXTAREA_NAMES.commentPlus,
                  $event
                )
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { "data-qa": "review_field_comment_minus" } },
        [
          _c("VTextarea", {
            ref: "review-field-comment-minus",
            staticClass: "review-form__textarea",
            attrs: {
              "data-qa": "review_field_comment_minus_input",
              name: _vm.TEXTAREA_NAMES.commentMinus,
              error:
                _vm.inputData.commentMinus.length > _vm.MAX_REVIEW_FIELD_LENGTH,
              counter:
                _vm.inputData.commentMinus.length > _vm.MAX_REVIEW_FIELD_LENGTH,
              maxlength: _vm.OVER_MAX_REVIEW_FIELD_LENGTH,
              outlined: "",
              "auto-grow": "",
              rows: "4",
              label: "Не понравилось",
              placeholder: "Какие недостатки вы отметили?",
              rules: _vm.rulesValidationComments,
              value: _vm.inputData.commentMinus,
            },
            on: {
              input: function ($event) {
                return _vm.handleInputTextarea(
                  _vm.TEXTAREA_NAMES.commentMinus,
                  $event
                )
              },
              focus: function ($event) {
                return _vm.handleFocusTextarea(
                  _vm.TEXTAREA_NAMES.commentMinus,
                  $event
                )
              },
              blur: function ($event) {
                return _vm.handleBlurTextarea(
                  _vm.TEXTAREA_NAMES.commentMinus,
                  $event
                )
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("ReviewPowerDiscountInfo", {
        model: {
          value: _vm.isReviewPowerDiscountInfoVisible,
          callback: function ($$v) {
            _vm.isReviewPowerDiscountInfoVisible = $$v
          },
          expression: "isReviewPowerDiscountInfoVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }