<template>
  <component
    :is="MainComponent"
    :value="value"
    :width="isMobile ? undefined : 360"
    scrollable
    @input="emit('input', $event)"
  >
    <VCard
      class="ui-kit-color-text"
      :max-height="isMobile ? undefined : 500"
    >
      <div v-if="!isMobile">
        <div class="d-flex align-center pa-4">
          <VIcon
            class="mr-4"
            color="uiKitIconSecondary"
            size="24"
            @click=" emit('input', false)"
          >
            ui-icon-close-not-a-circle
          </VIcon>
          <div class="ui-text ui-text_h6 ui-kit-color-text">
            Дарим скидку 300&nbsp;₽ за&nbsp;честный отзыв
          </div>
        </div>
        <VDivider />
      </div>
      <div
        v-if="isMobile"
        class="d-flex align-center px-4 pt-6 mb-2"
      >
        <div class="ui-text ui-text_h6 ui-kit-color-text">
          Дарим скидку 300&nbsp;₽ за&nbsp;честный отзыв
        </div>
      </div>
      <VCardText
        class="px-4"
        :class="isMobile ? 'py-0' : 'py-4'"
      >
        <InfoListItem
          v-for="(listItem, index) in INFO_LIST_ITEMS"
          :key="index"
          :class="{ 'mt-4': index > 0 }"
          :number="index + 1"
        >
          {{ listItem }}
        </InfoListItem>
      </VCardText>
      <VSheet
        class=""
        :class="isMobile ? 'px-4 pb-4 mt-5' : 'pa-2'"
      >
        <VBtn
          color="primary"
          depressed
          block
          @click="handleClickButton"
        >
          Хорошо
        </VBtn>
      </VSheet>
    </VCard>
  </component>
</template>

<script setup lang="ts">
import { VBottomSheet, VDialog } from 'vuetify/lib'
// eslint-disable-next-line import/extensions
import InfoListItem from 'components/common/InfoListItem/InfoListItem.vue'

defineProps<{
  value: boolean
}>()

const emit = defineEmits(['input'])

const isMobile = window.MOBILE_VERSION
const MainComponent = isMobile ? VBottomSheet : VDialog

const INFO_LIST_ITEMS = [
  'Напишите отзыв о враче',
  'Когда опубликуем отзыв, отправим промокод по СМС или в МедТочку',
  'В течение года сможете применить промокод в клинике, в которой принимал врач',
] as const

function handleClickButton() {
  emit('input', false)
}
</script>
